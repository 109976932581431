exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-balanta-tsx": () => import("./../../../src/pages/balanta.tsx" /* webpackChunkName: "component---src-pages-balanta-tsx" */),
  "component---src-pages-berbec-tsx": () => import("./../../../src/pages/berbec.tsx" /* webpackChunkName: "component---src-pages-berbec-tsx" */),
  "component---src-pages-capricorn-tsx": () => import("./../../../src/pages/capricorn.tsx" /* webpackChunkName: "component---src-pages-capricorn-tsx" */),
  "component---src-pages-despre-noi-tsx": () => import("./../../../src/pages/despre-noi.tsx" /* webpackChunkName: "component---src-pages-despre-noi-tsx" */),
  "component---src-pages-fecioara-tsx": () => import("./../../../src/pages/fecioara.tsx" /* webpackChunkName: "component---src-pages-fecioara-tsx" */),
  "component---src-pages-gemeni-tsx": () => import("./../../../src/pages/gemeni.tsx" /* webpackChunkName: "component---src-pages-gemeni-tsx" */),
  "component---src-pages-horoscop-azi-tsx": () => import("./../../../src/pages/horoscop-azi.tsx" /* webpackChunkName: "component---src-pages-horoscop-azi-tsx" */),
  "component---src-pages-horoscop-chinezesc-tsx": () => import("./../../../src/pages/horoscop-chinezesc.tsx" /* webpackChunkName: "component---src-pages-horoscop-chinezesc-tsx" */),
  "component---src-pages-horoscop-saptamanal-tsx": () => import("./../../../src/pages/horoscop-saptamanal.tsx" /* webpackChunkName: "component---src-pages-horoscop-saptamanal-tsx" */),
  "component---src-pages-horoscop-tsx": () => import("./../../../src/pages/horoscop.tsx" /* webpackChunkName: "component---src-pages-horoscop-tsx" */),
  "component---src-pages-horoscop-zilnic-chinezesc-tsx": () => import("./../../../src/pages/horoscop-zilnic-chinezesc.tsx" /* webpackChunkName: "component---src-pages-horoscop-zilnic-chinezesc-tsx" */),
  "component---src-pages-horoscop-zilnic-tsx": () => import("./../../../src/pages/horoscop-zilnic.tsx" /* webpackChunkName: "component---src-pages-horoscop-zilnic-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leu-tsx": () => import("./../../../src/pages/leu.tsx" /* webpackChunkName: "component---src-pages-leu-tsx" */),
  "component---src-pages-pesti-tsx": () => import("./../../../src/pages/pesti.tsx" /* webpackChunkName: "component---src-pages-pesti-tsx" */),
  "component---src-pages-rac-tsx": () => import("./../../../src/pages/rac.tsx" /* webpackChunkName: "component---src-pages-rac-tsx" */),
  "component---src-pages-sagetator-tsx": () => import("./../../../src/pages/sagetator.tsx" /* webpackChunkName: "component---src-pages-sagetator-tsx" */),
  "component---src-pages-scorpion-tsx": () => import("./../../../src/pages/scorpion.tsx" /* webpackChunkName: "component---src-pages-scorpion-tsx" */),
  "component---src-pages-taur-tsx": () => import("./../../../src/pages/taur.tsx" /* webpackChunkName: "component---src-pages-taur-tsx" */),
  "component---src-pages-varsator-tsx": () => import("./../../../src/pages/varsator.tsx" /* webpackChunkName: "component---src-pages-varsator-tsx" */),
  "component---src-templates-horoscope-chinese-page-tsx": () => import("./../../../src/templates/horoscopeChinesePage.tsx" /* webpackChunkName: "component---src-templates-horoscope-chinese-page-tsx" */),
  "component---src-templates-horoscope-page-azi-tsx": () => import("./../../../src/templates/horoscopePageAzi.tsx" /* webpackChunkName: "component---src-templates-horoscope-page-azi-tsx" */),
  "component---src-templates-horoscope-page-tsx": () => import("./../../../src/templates/horoscopePage.tsx" /* webpackChunkName: "component---src-templates-horoscope-page-tsx" */)
}

